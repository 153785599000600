import React from "react";
import styles from "./Box.module.css";
import ChartPie from "../ChartPie/ChartPie";
import ChartBar from "../ChartBar/ChartBar";
import ChartLabels from "../ChartLabels/ChartLabels";
import RatiosCompare from "../RatiosCompare/RatiosCompare";
import GridTexts from "../GridTexts/GridTexts";

const Box = (boxData) => {
  if (!boxData) {
    console.error("boxData is undefined");
    return null;
  }

  const contentType = {
    text: <div className={styles.boxText}>{boxData.content}</div>,
    ChartPie: (
      <div className={styles.boxContent}>
        <ChartPie
          {...boxData.content}
          onClickLabel={boxData.onClickLabel}
          filter={"emotion"}
        />
        <ChartLabels
          {...boxData.content}
          onClickLabel={boxData.onClickLabel}
          filter={"emotion"}
        />
      </div>
    ),
    ChartBar: (
      <div className={styles.boxContent}>
        <ChartBar
          {...boxData.content}
          onClickLabel={boxData.onClickLabel}
          filter={"type"}
        />
        <ChartLabels
          {...boxData.content}
          onClickLabel={boxData.onClickLabel}
          filter={"type"}
        />
      </div>
    ),
    RatiosCompare: (
      <div className={styles.boxContent}>
        <RatiosCompare
          {...boxData.content}
          onClickLabel={boxData.onClickLabel}
          filter={"sender"}
        />
      </div>
    ),
    GridTexts: (
      <div className={styles.boxContent}>
        <GridTexts {...boxData.content} />
      </div>
    ),
  };

  return (
    <div className={styles.box}>
      {boxData.title && <h3 className={styles.boxTitle}>{boxData.title}</h3>}
      {boxData.caption && (
        <div className={styles.boxCaption}>{boxData.caption}</div>
      )}
      {boxData.content && contentType[boxData.type ?? "text"]}
    </div>
  );
};
export default Box;
