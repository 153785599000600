import React, { useEffect, useRef, useState } from "react";
import Popup from "../../Popup/Popup";
import Button from "../../Button/Button";
import inputStyles from "../../Input/Input.module.css";
import clsx from "clsx";
import styles from "./ConnectingTG.module.css";
import {
  STATUS_CONNECT_TG,
  getStorageNamePhone,
} from "../../../utils/constants";

const ConnectingTG = ({
  setConnectingTG,
  connectingTgStatus,
  clearProfiles,
  sendPhoneTG,
  sendCodeTG,
  sendPasswordTG,
  setNotifyError,
  setIsLoading,
}) => {
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const ref = useRef(null);
  const closePopup = () => {
    setPhone("");
    setCode("");
    setPassword("");
    setConnectingTG(STATUS_CONNECT_TG.NONE);
  };

  const createContent = (status) => {
    let content = {};

    switch (status) {
      case STATUS_CONNECT_TG.NONE:
        content = {
          text: "Произошла ошибка",
          value: "",
          onChange: () => {},
          action: () => {},
        };
        break;
      case STATUS_CONNECT_TG.PHONE:
        content = {
          text: "Введите номер телефона, зарегистрированного в telegram в формате 79171234567",
          value: phone,
          onChange: (e) => setPhone(e.target.value),
          action: async () => {
            const res = await sendPhoneTG(phone);
            if (res?.status === "done") {
              setConnectingTG(STATUS_CONNECT_TG.CODE);
            }
            if (res?.detail === "FLOOD_WAIT") {
              setNotifyError(
                "Телеграм не разрешает авторизацию, так как ранее было много неудачных попыток. Попробуйте через 24часа или другой аккаунт"
              );
            }
          },
          lengthContent: 11,
        };
        break;
      case STATUS_CONNECT_TG.CODE:
        content = {
          text: "Введите код для авторизации, который был направлен вам в telegram",
          value: code,
          onChange: (e) => setCode(e.target.value),
          action: async () => {
            const res = await sendCodeTG(code);
            if (res?.detail === "auth_success") {
              setConnectingTG(STATUS_CONNECT_TG.NONE);
              clearProfiles();
            }
            if (res?.detail === "2fa") {
              setConnectingTG(STATUS_CONNECT_TG.PASSWORD);
            }
            if (res?.detail === "'Error in Code field'") {
              setNotifyError("Код не верный");
            }
            // if (!res) {
            //   setConnectingTG(STATUS_CONNECT_TG.NONE);
            // }
          },
          lengthContent: 5,
        };
        break;
      case STATUS_CONNECT_TG.PASSWORD:
        content = {
          text: "У вас включена 2-факторная авторизация. Необходимо ввести пароль.",
          value: password,
          onChange: (e) => setPassword(e.target.value),
          action: async () => {
            const res = await sendPasswordTG(password);
            if (res?.detail === "auth_success") {
              setConnectingTG(STATUS_CONNECT_TG.NONE);
              setIsLoading(true);
              setTimeout(() => clearProfiles(), 4000);
            }
            if (res?.detail === "'Error in Code field'") {
              setNotifyError("Пароль не верный");
            }
            if (!res) {
              // setConnectingTG(STATUS_CONNECT_TG.NONE);
            }
          },
        };
        break;
      default:
        // Handle default case if needed
        content = {
          text: "-",
          value: null,
          onChange: () => {},
          action: () => {},
        };
        break;
    }

    return content;
  };
  return (
    <Popup
      isOpen={connectingTgStatus !== STATUS_CONNECT_TG.NONE}
      setPopupOpened={closePopup}
      title={"Подключение к Telegram"}
      className={styles.popupContainer}
    >
      <p className={styles.text}>{createContent(connectingTgStatus).text}</p>
      <div className={inputStyles.field}>
        <input
          type={
            connectingTgStatus === STATUS_CONNECT_TG.PASSWORD
              ? "password"
              : "text"
          }
          value={createContent(connectingTgStatus).value}
          onChange={createContent(connectingTgStatus).onChange}
          placeholder=""
          className={clsx(inputStyles.input, styles.input)}
          required
          minLength={createContent(connectingTgStatus).lengthContent ?? null}
          maxLength={createContent(connectingTgStatus).lengthContent ?? null}
          ref={ref}
        />
      </div>
      <Button
        text="Отправить"
        type="primary"
        disabled={!ref.current?.validity?.valid}
        selector="button"
        action={createContent(connectingTgStatus).action}
      />
    </Popup>
  );
};

export default ConnectingTG;
