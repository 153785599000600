import React from "react";
import Popup from "../../Popup/Popup";
import Button from "../../Button/Button";

const ConnectingWA = ({ isOpen, setIsConnectingWa, qrWa, clearProfiles }) => {
  return (
    <Popup
      isOpen={isOpen}
      setPopupOpened={setIsConnectingWa}
      title={"Подключение к Whatsapp"}
    >
      <p>Инструкция для подключения</p>
      <ol>
        <li>Откройте Whatsapp на телефоне.</li>
        <li>
          Нажмите <b>Меню</b> или <b>Настройки</b>.
        </li>
        <li>
          Нажмите <b>Связанные устройства</b>.
        </li>
        <li>
          Нажмите <b>Привязка устройства</b>.
        </li>
        <li>Наведите камеру на экран, чтобы считать этот код.</li>
        <li>После успешной авторизации на телефоне нажмите кнопку ниже</li>
      </ol>
      <img src={qrWa} style={{ margin: 50, width: "50%" }} />
      <Button
        text="Телефон подключился"
        type="primary"
        selector="button"
        action={() => {
          setIsConnectingWa(false);
          clearProfiles();
        }}
      />
    </Popup>
  );
};

export default ConnectingWA;
