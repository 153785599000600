import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import styles from "./Layout.module.css";

const Layout = ({ children, onLogout, currentUser }) => (
  <div className={styles.page}>
    <div className={styles.container}>
      <Header onLogout={onLogout} currentUser={currentUser} />
      <main className={styles.main}>{children}</main>
      <Footer />
    </div>
  </div>
);
export default Layout;
