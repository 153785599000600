import React from "react";
import styles from "./RatiosCompare.module.css";
import { calculatePercentages } from "../../utils/utils";

const RatiosCompare = (data) => {
  const percentages = calculatePercentages(
    Object.values(data)
      .filter((item) => item.label)
      .map((item) => item.value)
  );

  return (
    <div className={styles.items}>
      {Object.values(data)
        .filter((item) => item.label)
        .map((item, itemIndex) => (
          <div className={styles.item} key={itemIndex}>
            <div className={styles.itemMain}>
              <div
                onClick={() => {
                  data.onClickLabel(data.filter, item.label);
                }}
                className={styles.itemName}
              >
                {item.label}
              </div>
              <div className={styles.itemValue}>{item.value}</div>
              <div className={styles.itemPercent}>({item.percent}%)</div>
            </div>
            <div className={styles.itemProgress}>
              <div
                className={styles.itemProgressValue}
                style={{ width: `${percentages[itemIndex]}%` }}
              />{" "}
            </div>
          </div>
        ))}
    </div>
  );
};
export default RatiosCompare;
