import { useEffect } from "react";
import io from "socket.io-client";
import { WEBSOCKET_URL } from "../../utils/constants";

const WebSocketComponent = ({
  id: userId,
  handleUpdateStatusOrder,
  setAllReports,
}) => {
  useEffect(() => {
    const newSocket = io(WEBSOCKET_URL, {
      transports: ["websocket", "polling"],
    });

    newSocket.on("connect", () => {
      console.log("Подключено к серверу");
      newSocket.emit("register user", userId);
    });

    newSocket.on("emotion analysis complete", (result) => {
      console.log("Получен результат анализа:", result);
    });
    newSocket.on("message", (message) => {
      handleUpdateStatusOrder(message, setAllReports);
      // console.log("Получено сообщение:", message);
      // Обработка сообщения (например, отображение уведомления)
    });

    return () => newSocket.close();
  }, []);

  return <></>;
};

export default WebSocketComponent;
