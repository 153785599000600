import React from "react";
import {components} from "react-select";
import {ReactComponent as Caret} from '../assets/img/caret.svg';
import {ReactComponent as Close} from '../assets/img/close.svg';

const styles = () => ({
  dropdownIndicator: (provided) => ({
    ...provided,
    color: 'var(--color-grey-dark)',
    padding: 0,
    width: 20,
    height: 20,
    marginRight: 16,
    cursor: 'pointer',

    '&:hover': {
      color: 'var(--color-black)',
    },
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: 'var(--color-grey-dark)',
    padding: 0,
    width: 20,
    height: 20,
    marginRight: 8,
    cursor: 'pointer',

    '&:hover': {
      color: 'var(--color-black)',
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    opacity: 0,
  }),
  input: (provided) => ({
    ...provided,
    color: 'var(--color-black)',
    fontSize: 'var(--fs-small)',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--color-grey-dark)',
    fontSize: 'var(--fs-small)',
    opacity: '1',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: 'var(--color-white)',
    color: state.isSelected ? 'var(--color-main)' : 'var(--color-black)',
    fontSize: 'var(--fs-small)',
    padding: '12px 16px',
    cursor: state.isSelected ? 'default' : 'pointer',

    '&:hover': {
      backgroundColor: 'var(--color-grey-light)',
      color: 'var(--color-black)',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: 'var(--fs-small)',
    color: 'var(--color-black)'
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 16
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: 48,
    borderColor: 'var(--color-grey)',
    backgroundColor: 'var(--color-white)',
    boxShadow: state.menuIsOpen ? '0 0 0 4px var(--color-main-shadow)' : '',
    color: 'var(--color-grey-dark)',
    borderRadius: 8,
    cursor: 'pointer',

    '&:hover': {
      borderColor: 'var(--color-grey)',
    },
  }),

  menu: (provided) => ({
    ...provided,
    width: 'auto',
    minWidth: '100%',
    border: '1px solid var(--color-grey)',
    boxShadow: 'none',
    borderRadius: 8,
    padding: 0,
    marginTop: 12,
    overflow: 'hidden'
  }),

  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),
});

export const selectTheming = () => ({
  styles: styles(),
});

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}><Caret/></components.DropdownIndicator>
  );
};

export const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}><Close/></components.ClearIndicator>
  );
};


