import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { TextFormat } from "@mui/icons-material";

const UserFormModal = ({ open, onClose, onAddUser }) => {
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    password: "",
    profile_tg: "",
    profile_wa: "",
    access: true,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleSubmit = () => {
    onAddUser(newUser);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New User</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="name"
          label="Name"
          type="text"
          fullWidth
          value={newUser.name}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          name="email"
          label="Email"
          type="email"
          fullWidth
          value={newUser.email}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          name="password"
          label="Password"
          type="password"
          fullWidth
          value={newUser.password}
          onChange={handleInputChange}
        />
        <p>Если не заполнять TG и WA ID, то они создадутся автоматически</p>
        <TextField
          margin="dense"
          name="profile_tg"
          label="Profile ID TG"
          type="text"
          fullWidth
          value={newUser.profile_tg}
          onChange={handleInputChange}
          placeholder="Если пусто - создастся автоматом"
        />
        <TextField
          margin="dense"
          name="profile_wa"
          label="Profile ID WA"
          type="text"
          fullWidth
          value={newUser.profile_wa}
          onChange={handleInputChange}
          placeholder="Если пусто - создастся автоматом"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserFormModal;
