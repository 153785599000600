import { useEffect } from "react";
import "./Popup.css";
import { Link } from "react-router-dom";

const Popup = ({ isOpen, setPopupOpened, title, children, className }) => {
  function handleClickClosePopup() {
    setPopupOpened(false);
  }
  useEffect(() => {
    function handleKeyEsc(e) {
      e.key === "Escape" && setPopupOpened(false);
    }
    isOpen && document.addEventListener("keydown", handleKeyEsc);

    return () => document.removeEventListener("keydown", handleKeyEsc);
  }, [isOpen]);

  function handleClickByOverlay(e) {
    e.currentTarget === e.target && setPopupOpened(false);
  }

  return (
    <section
      className={`popup-modal popup-modal_type_notify ${
        isOpen && "popup-modal_opened"
      }`}
      onClick={handleClickByOverlay}
    >
      <div
        className={`popup-modal__container popup-modal__container_notify ${className}`}
      >
        <button
          type="button"
          className="popup-modal__close"
          onClick={handleClickClosePopup}
        />
        <div className="popup-modal__header">
          <h2 className="popup-modal__header-title">{title}</h2>
        </div>
        {children}
      </div>
    </section>
  );
};
export default Popup;
