import React from "react";
import styles from "./SupportBox.module.css";
import Button from "../Button/Button";

const SupportBox = () => {
  return (
    <div className={styles.support}>
      <div className={styles.supportTitle}>Нужна помощь?</div>
      <div className={styles.supportActions}>
        <Button
          {...{
            text: "Обратиться в поддержку",
            type: "primary",
            selector: "a",
            link: "https://t.me/ai_star_info",
            target: "_blank",
          }}
        />
        <Button
          {...{
            text: "Инструкция",
            type: "secondary",
            selector: "a",
            link: "https://www.youtube.com/watch?v=xX9pyLOoRvU",
            target: "_blank",
          }}
        />
      </div>
    </div>
  );
};

export default SupportBox;
