import React from 'react';
import styles from './ReportPlaceholder.module.css';

const ReportPlaceholder = ({title, subtitle}) => {
  return (
    <div className={styles.placeholder}>
      {title ? <div className={styles.placeholderTitle}>{title}</div> : ''}
      {subtitle ? <div className={styles.placeholderText}>{subtitle}</div> : ''}
    </div>
  );
};

export default ReportPlaceholder;