import React from "react";
import Chart from "react-apexcharts";

const ChartBar = (Data) => {
  const data = Object.values(Data).filter((item) => item.label);

  const series = [
    {
      data: data.map((item) => item.value),
    },
  ];

  const options = {
    chart: {
      type: "bar",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: true,
      padding: { left: -11, right: 0, top: -30, bottom: -15 },
    },
    plotOptions: {
      bar: {
        barHeight: 8,
        distributed: true,
        horizontal: true,
      },
    },
    colors: data.map((item) => item.color),
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    yaxis: {
      show: false,
    },
    xaxis: {
      labels: {
        style: {
          colors: "#4F4F4F",
        },
      },
      axisBorder: {
        color: "#BDBDBD",
      },
      axisTicks: {
        color: "#BDBDBD",
      },
    },
    chart: {
      events: {
        dataPointSelection: (event, chartContext, { dataPointIndex }) => {
          const clickedLabel = data[dataPointIndex].label;
          Data.onClickLabel(Data.filter, clickedLabel);
        },
      },
    },
  };

  return <Chart options={options} series={series} type="bar" />;
};
export default ChartBar;
