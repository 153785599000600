import React from "react";
import Chart from "react-apexcharts";

const ChartPie = (Data) => {
  const data = Object.values(Data).filter((item) => item.label);
  const series = data.map((item) => item.value);

  const options = {
    colors: data.map((item) => item.color),
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 576,
        options: {
          chart: {
            width: 320,
          },
        },
      },
      {
        breakpoint: 375,
        options: {
          chart: {
            width: 264,
          },
        },
      },
    ],
    chart: {
      events: {
        click: (_, __, opts) => {
          let label = data[opts.dataPointIndex].label;
          if (label === "Позитивные") label = "Позитивный";
          if (label === "Негативные") label = "Негативный";
          if (label === "Нейтральные") label = "Нейтральный";
          Data.onClickLabel(Data.filter, label);
        },
      },
    },
    labels: data.map((item) => item.label),
  };

  return (
    <Chart
      options={options}
      series={series}
      labels={data.map((item) => item.label)}
      type="donut"
    />
  );
};
export default ChartPie;
