import { BASE_URL } from "./constants";

class MainApi {
  constructor(options) {
    this._options = options;
    this._server = this._options.server;
  }

  _headers() {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  }

  _handleErrorAndResponse(res) {
    if (res.status === 200 || res.status === 201) {
      return res.json();
    } else if (
      res.status === 400 ||
      res.status === 401 ||
      res.status === 403 ||
      res.status === 404 ||
      res.status === 409
    ) {
      return res
        .json()
        .then((jsonError) => ({ error: jsonError.message, ...jsonError }));
    } else if (res.status === 500) {
      return { error: "Ошибка на стороне сервера" };
    } else {
      return Promise.reject(`Ошибка: ${res.status}`);
    }
  }

  async onCheckUser() {
    const res = await fetch(`${this._server}/users/me`, {
      method: "GET",
      credentials: "include",
      headers: this._headers(),
    });
    return this._handleErrorAndResponse(res);
  }

  async onRegister(email, password, name) {
    const res = await fetch(`${this._server}/signup`, {
      method: "POST",
      credentials: "include",
      headers: this._headers(),
      body: JSON.stringify({ email, password, name }),
    });
    return this._handleErrorAndResponse(res);
  }

  async onLogin({ email, password }) {
    const res = await fetch(`${this._server}/signin`, {
      credentials: "include",
      method: "POST",
      headers: this._headers(),
      body: JSON.stringify({ email, password }),
    });
    return this._handleErrorAndResponse(res);
  }

  async onGetUserInfo() {
    const res = await fetch(`${this._server}/users/me`, {
      method: "GET",
      headers: this._headers(),
      credentials: "include",
    });
    return this._handleErrorAndResponse(res);
  }

  async onEditProfile({ name, email }) {
    const res = await fetch(`${this._server}/users/me`, {
      method: "PATCH",
      headers: this._headers(),
      credentials: "include",
      body: JSON.stringify({ name, email }),
    });
    return this._handleErrorAndResponse(res);
  }

  async loadProfiles() {
    const res = await fetch(`${this._server}/profiles`, {
      method: "GET",
      headers: this._headers(),
      credentials: "include",
    });
    return this._handleErrorAndResponse(res);
  }
  async getQrWa() {
    const res = await fetch(`${this._server}/profiles/getQrWa`, {
      method: "GET",
      headers: this._headers(),
      credentials: "include",
    });
    return this._handleErrorAndResponse(res);
  }

  async sendPhoneTG(profile_id, phone) {
    const res = await fetch(`${this._server}/profiles/sendPhoneTG`, {
      method: "POST",
      headers: this._headers(),
      credentials: "include",
      body: JSON.stringify({ phone, profile_id }),
    });
    return this._handleErrorAndResponse(res);
  }
  async sendCodeTG(profile_id, code) {
    const res = await fetch(`${this._server}/profiles/sendCodeTG`, {
      method: "POST",
      headers: this._headers(),
      credentials: "include",
      body: JSON.stringify({ code, profile_id }),
    });
    return this._handleErrorAndResponse(res);
  }

  async sendPasswordTG(profile_id, password) {
    const res = await fetch(`${this._server}/profiles/sendPasswordTG`, {
      method: "POST",
      headers: this._headers(),
      credentials: "include",
      body: JSON.stringify({ password, profile_id }),
    });
    return this._handleErrorAndResponse(res);
  }
  async getChats(profile_id) {
    const res = await fetch(`${this._server}/chats/`, {
      method: "POST",
      headers: this._headers(),
      credentials: "include",
      body: JSON.stringify({ profile_id }),
    });
    return this._handleErrorAndResponse(res);
  }
  async createReport({ profile_id, chat_id, start_date, end_date, name }) {
    const res = await fetch(`${this._server}/reports/createReport/`, {
      method: "POST",
      headers: this._headers(),
      credentials: "include",
      body: JSON.stringify({ profile_id, chat_id, start_date, end_date, name }),
    });
    return this._handleErrorAndResponse(res);
  }

  async logoutMessenger(profile_id) {
    const res = await fetch(`${this._server}/profiles/logout/${profile_id}`, {
      method: "GET",
      headers: this._headers(),
      credentials: "include",
    });
    return this._handleErrorAndResponse(res);
  }

  async onLogout() {
    const res = await fetch(`${this._server}/signout`, {
      method: "GET",
      credentials: "include",
      headers: this._headers(),
    });
    return this._handleErrorAndResponse(res);
  }

  async getAllUsers() {
    const res = await fetch(`${this._server}/admin/allUsers`, {
      method: "GET",
      credentials: "include",
      headers: this._headers(),
    });
    return this._handleErrorAndResponse(res);
  }

  async updateUser(userData) {
    const res = await fetch(`${this._server}/admin/updateUser`, {
      method: "POST",
      credentials: "include",
      headers: this._headers(),
      body: JSON.stringify(userData),
    });
    return this._handleErrorAndResponse(res);
  }

  async addUserFromAdmin(userData) {
    const res = await fetch(`${this._server}/admin/addUser`, {
      method: "POST",
      credentials: "include",
      headers: this._headers(),
      body: JSON.stringify(userData),
    });
    return this._handleErrorAndResponse(res);
  }
  async deleteUser(id) {
    const res = await fetch(`${this._server}/admin/deleteUser`, {
      method: "POST",
      credentials: "include",
      headers: this._headers(),
      body: JSON.stringify({ id }),
    });
    return this._handleErrorAndResponse(res);
  }
  async getReports() {
    const res = await fetch(`${this._server}/reports/getReports`, {
      method: "GET",
      credentials: "include",
      headers: this._headers(),
    });
    return this._handleErrorAndResponse(res);
  }
  async getReport(id) {
    const res = await fetch(`${this._server}/reports/getReport/${id}`, {
      method: "GET",
      credentials: "include",
      headers: this._headers(),
    });
    return this._handleErrorAndResponse(res);
  }
  async getMessages(id) {
    const res = await fetch(`${this._server}/reports/getMessages/${id}`, {
      method: "GET",
      credentials: "include",
      headers: this._headers(),
    });
    return this._handleErrorAndResponse(res);
  }
  async delByReport(id) {
    const res = await fetch(`${this._server}/reports/delByReport/${id}`, {
      method: "DELETE",
      credentials: "include",
      headers: this._headers(),
    });
    return this._handleErrorAndResponse(res);
  }
  async getLogs() {
    const response = await fetch(`${this._server}/logs`, {
      method: "GET",
      credentials: "include",
      headers: this._headers(),
    });
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "logs.txt";
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  }
}

const mainApi = new MainApi({
  server: BASE_URL,
});

export default mainApi;
