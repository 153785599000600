import React from "react";
import styles from "./HistoryReports.module.css";
import { TEXTS_STATUSES } from "../../utils/constants";
import { formatDateToWappi } from "../../utils/utils";

const HistoryReports = ({ reports, getReport }) => {
  const getColorStatus = (status) => {
    if (status === "completed") {
      return "rgb(144, 190, 109)";
    }
    if (status === "error") {
      return "rgb(249, 65, 68)";
    }
    return "rgb(249, 199, 79)";
  };
  return (
    <div className={styles.reports}>
      {Object.values(reports).map((report, reportIndex) => (
        <div
          className={styles.report}
          key={reportIndex}
          onClick={() => getReport(report.id)}
          title={report.time ? `создан ${formatDateToWappi(report.time)}` : ""}
        >
          {" "}
          <div
            className={styles.gridItemColor}
            style={{ backgroundColor: getColorStatus(report.status_order) }}
          />
          <div className={styles.reportChat}>{report.name}</div>
          <div className={styles.reportDate}>
            {report.start_date} - {report.end_date} -{" "}
            {TEXTS_STATUSES[report.status_order]}
          </div>
        </div>
      ))}
    </div>
  );
};

export default HistoryReports;
