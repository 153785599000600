import React from "react";
import styles from "./ChartLabels.module.css";
import { calculatePercentages } from "../../utils/utils";

const ChartLabels = (data) => {
  const percentages = calculatePercentages(
    Object.values(data)
      .filter((item) => item.label)
      .map((item) => item.value)
  );

  return (
    <div className={styles.labels}>
      {Object.values(data)
        .filter((item) => item.label)
        .map((label, labelIndex) => (
          <div className={styles.label} key={labelIndex}>
            <div className={styles.labelTop}>
              <div className={styles.labelValue}>{label.value}</div>
              <div className={styles.labelPercent}>
                ({percentages[labelIndex]}%)
              </div>
            </div>
            <div className={styles.labelBottom}>
              <div
                className={styles.labelColor}
                style={{ backgroundColor: label.color }}
              />
              <div
                onClick={() => {
                  let text = label.label;
                  if (label.label === "Позитивные") text = "Позитивный";
                  if (label.label === "Негативные") text = "Негативный";
                  if (label.label === "Нейтральные") text = "Нейтральный";
                  data.onClickLabel(data.filter, text);
                }}
                className={styles.labelName}
              >
                {label.label}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
export default ChartLabels;
