import React from "react";
import styles from "./Button.module.css";

const Button = ({
  text,
  type,
  selector,
  link,
  target,
  action,
  disabled,
  className,
  icon: Icon,
}) => {
  const classNames = `${styles.button} ${styles[`button--${type}`]} ${
    className ? styles[className] : ""
  }`;

  const content = (
    <>
      {Icon && <Icon className={styles.icon} />}
      {text}
    </>
  );

  if (selector === "a") {
    return (
      <a href={link} className={classNames} target={target}>
        {content}
      </a>
    );
  }

  return (
    <button onClick={action} className={classNames} disabled={disabled}>
      {content}
    </button>
  );
};

export default Button;
