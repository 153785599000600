export function calculatePercentages(values) {
  const totalSum = values.reduce((acc, value) => acc + value, 0);

  if (totalSum === 0) {
    return values.map(() => 0);
  }

  let percentages = values.map((value) => Math.round((value / totalSum) * 100));

  const sumPercentages = percentages.reduce(
    (acc, percentage) => acc + percentage,
    0
  );
  if (sumPercentages !== 100) {
    const diff = sumPercentages - 100;
    for (let i = 0; i < percentages.length; i++) {
      if (percentages[i] > Math.abs(diff)) {
        percentages[i] -= Math.sign(diff);
        break;
      }
    }
  }

  return percentages;
}

export function currentDateTime() {
  const date = new Date();
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };
  return date
    .toLocaleString("ru-RU", options)
    .replace(",", " -")
    .replace(/\//g, ".");
}

export function formatDateToWappi(dateInitial) {
  const date = dateInitial ? new Date(dateInitial) : new Date();
  if (date instanceof Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Увеличиваем месяц на 1, так как отсчет месяцев в JavaScript начинается с 0
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }
  return "";
}
export function formatDateToShortString(dateInitial) {
  const date = dateInitial ? new Date(dateInitial) : new Date();
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Месяцы начинаются с 0
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
}
export const getEndDay = (day) => new Date(day.setHours(23, 59, 59, 999));

export function replaceValuesForMessages(arr) {
  const emotionMap = {
    neutral: "Нейтральный",
    positive: "Позитивный",
    negative: "Негативный",
  };

  const typeMap = {
    information: "Информация",
    question: "Вопрос",
    greeting: "Приветствие",
    answer: "Ответ",
    advertisement: "Реклама",
    complaint: "Жалоба",
  };

  return arr.map((item) => {
    return {
      ...item,
      emotionMessage: emotionMap[item.emotionMessage] || item.emotionMessage,
      typeMessage: typeMap[item.typeMessage] || item.typeMessage,
    };
  });
}
export function setCookie(name, value, options = {}) {
  options = {
    path: "/",
    // при необходимости добавьте другие значения по умолчанию
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}
