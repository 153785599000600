import React, { useEffect, useState } from "react";
import styles from "./Header.module.css";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import { ReactComponent as Logout } from "../../assets/img/logout.svg";
import Button from "../Button/Button";
import { Navigate, useNavigate } from "react-router-dom";

const Header = ({ onLogout, currentUser }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const adminButton = {
    text: "Админка",
    type: "primary",
    selector: "button",
    action: () => navigate("/admin"),
  };

  const logOutAction = {
    text: isMobile ? <Logout /> : "Выйти",
    type: "secondary",
    selector: "button",
    action: onLogout,
    className: "button--icon",
  };

  return (
    <header className={styles.header}>
      <div className={styles.headerLogo}>
        <Logo />
        <div className={styles.headerLogoText}>Домовой градусник</div>
      </div>
      <div className={styles.headerUser}>
        {currentUser?.email}
        {currentUser.access ? (
          <span style={{ color: "green" }}> </span>
        ) : (
          <span style={{ color: "red" }}> не активен</span>
        )}
      </div>
      <div className={styles.headerAction}>
        {currentUser.is_admin && <Button {...adminButton} />}
        <Button {...logOutAction} />
      </div>
    </header>
  );
};

export default Header;
