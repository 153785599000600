import React, { useEffect, useRef, useState } from "react";
import Popup from "../Popup/Popup";
import Button from "../Button/Button";
import clsx from "clsx";
import styles from "./SelectChats.module.css";
import { getStorageNameSelectedChats } from "../../utils/constants";

const SelectChats = ({
  isOpen,
  setPopupClose,
  allChats,
  profile_id,
  updateChats,
}) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const nameLocalStorage = getStorageNameSelectedChats(profile_id);

  useEffect(() => {
    const storedChats = localStorage.getItem(nameLocalStorage);
    if (storedChats) {
      setSelectedValues(JSON.parse(storedChats));
    }
  }, [nameLocalStorage]);

  const handleSave = () => {
    localStorage.setItem(nameLocalStorage, JSON.stringify(selectedValues));
    setPopupClose();
  };
  const ChatCheckbox = ({ id, name, isSelected, onCheckboxChange }) => (
    <div key={id}>
      <input
        type="checkbox"
        id={`chat-${id}`}
        checked={isSelected}
        onChange={() => onCheckboxChange(id)}
        className={styles.input}
      />
      <label htmlFor={`chat-${id}`}>{name}</label>
    </div>
  );

  const handleCheckboxChange = (id) => {
    setSelectedValues((prevSelectedValues) => {
      if (prevSelectedValues.includes(id)) {
        return prevSelectedValues?.filter((val) => val !== id);
      } else {
        return [...prevSelectedValues, id];
      }
    });
  };

  return (
    <Popup
      isOpen={isOpen}
      setPopupOpened={setPopupClose}
      title={"Настройка чатов"}
      className={styles.popupContainer}
    >
      <p className={styles.text}>
        {allChats?.length
          ? `Выберите рабочие чаты. Рекомендуется настраивать чаты через некоторое
        время после подключения, чтобы наш сервис успел их найти`
          : `Чаты еще в процессе загрузки. Попробуйте, пожалуйста, позже`}
      </p>
      {!!allChats?.length ? (
        <>
          <div className={styles.form}>
            {allChats &&
              allChats.map((option) => (
                <ChatCheckbox
                  key={option.id}
                  id={option.id}
                  name={option.name}
                  isSelected={selectedValues.includes(option.id)}
                  onCheckboxChange={handleCheckboxChange}
                />
              ))}
          </div>
          <div className={styles.buttons}>
            <Button
              text={`обновить`}
              type="secondary"
              selector="button"
              action={() => updateChats(profile_id)}
            />
            <Button
              text={`Сохранить ${
                selectedValues.length
                  ? "(" +
                    (allChats?.filter((c) => selectedValues.includes(c.id))
                      .length ?? 0) +
                    ")"
                  : ""
              }`}
              type="primary"
              selector="button"
              action={handleSave}
            />
          </div>
        </>
      ) : (
        <Button
          text={`обновить`}
          type="secondary"
          selector="button"
          action={() => updateChats(profile_id)}
        />
      )}
    </Popup>
  );
};

export default SelectChats;
