import React from "react";
import { usePDF } from "react-to-pdf";
import Box from "../../components/Box/Box";
import Button from "../Button/Button";
import styles from "./Report.module.css";
import { createBodyReport } from "../HistoryReports/utils";
import { STATUSES_ORDER, TEXTS_STATUSES } from "../../utils/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import ChatIcon from "@mui/icons-material/Chat";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloseIcon from "@mui/icons-material/Close";
import { formatDateToShortString } from "../../utils/utils";
const Report = ({
  responseReport,
  onClose,
  onLoadMessages,
  delByReport,
  onClickLabel,
}) => {
  const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });
  const reportAction = (
    <div className={styles.reportAction}>
      <Button
        type="secondary"
        selector="button"
        text="Удалить отчет"
        action={() => delByReport(responseReport._id)}
        icon={DeleteIcon}
      />
      <Button
        type="secondary"
        selector="button"
        className="buttonMarginLeft"
        text="Прочитать сообщения"
        action={() => onLoadMessages(responseReport._id)}
        icon={ChatIcon}
      />
      {responseReport.status_order === STATUSES_ORDER.COMPLETED && (
        <Button
          className="buttonMarginLeft"
          type="secondary"
          selector="button"
          text="Скачать в PDF "
          action={() => toPDF()}
          icon={PictureAsPdfIcon}
        />
      )}
      <Button
        className="buttonMarginLeft"
        type="secondary"
        selector="button"
        text="Закрыть"
        action={onClose}
        icon={CloseIcon}
      />
    </div>
  );
  if (responseReport.status_order === STATUSES_ORDER.COMPLETED) {
    const report = createBodyReport(responseReport);
    return (
      <div className={styles.report}>
        <div className={styles.reportHeader}>
          <h1 className={styles.reportTitle}>{report.name}</h1>
          {reportAction}
        </div>
        <div className={styles.reportRows} ref={targetRef}>
          {Object.values(report.data).map((row, rowIndex) => (
            <div
              className={`${styles.reportRow} ${
                styles[`columns-${row.columnsCount}`]
              }`}
              key={rowIndex}
            >
              {row.items.map((col, colIndex) => (
                <div className={styles.reportCol} key={colIndex}>
                  {col.map((boxData, boxIndex) => (
                    <Box
                      onClickLabel={onClickLabel}
                      {...boxData}
                      key={boxIndex}
                    />
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.report}>
        <div className={styles.reportHeader}>
          <Box
            type="text"
            title={
              responseReport.status_order === STATUSES_ORDER.ERROR ||
              responseReport.error
                ? `ошибка при выгрузке отчета`
                : `Статус отчета - ${
                    TEXTS_STATUSES[responseReport.status_order] ?? "создается"
                  } ${responseReport.progress ?? ""}`
            }
            content={
              <h3 className={styles.reportTitleLoading}>
                {responseReport.name}
                {` `}
                {formatDateToShortString(responseReport.start_date)} -
                {formatDateToShortString(responseReport.end_date)}
              </h3>
            }
          ></Box>
          {reportAction}
        </div>
      </div>
    );
  }
};
export default Report;
